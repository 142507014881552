export const CheckMobile = () => {
    return (
        <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z"
                fill="#38C399"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0679 6.5L10.4872 15.7691L6.74573 12.0382L4.875 13.9089L10.4872 19.5L20.9338 8.37073L19.0679 6.5Z"
                fill="white"
            />
        </svg>
    )
}
