export const Check = () => {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15Z"
                fill="#38C399"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.002 7.5 12.1 18.195 7.784 13.89 5.625 16.05l6.476 6.451L24.154 9.659 22.002 7.5Z"
                fill="#fff"
            />
        </svg>
    )
}
